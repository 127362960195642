import React, { useState, useEffect } from 'react';
import { Sun, Moon, X, Menu, Calendar, GraduationCap, Clock, Github, ChevronLeft, ChevronRight } from 'lucide-react';
import { motion } from 'framer-motion';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

const App = () => {
  const [darkMode, setDarkMode] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [appInfo, setAppInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [darkMode]);

  useEffect(() => {
    fetch('https://api.quanhd.net/tkb_app.json')
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setAppInfo(data);
        setLoading(false);
      })
      .catch(err => {
        setError('Không thể tải thông tin ứng dụng. Vui lòng thử lại sau.');
        setLoading(false);
        console.error('Fetch error:', err);
      });
  }, []);

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const fadeInUp = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
  };

  const screenshots = [
    { src: "/assets/images/Screenshot_20240918-135456_QTimeTable.png", alt: "Màn hình đăng nhập", description: "Màn hình đăng nhập của ứng dụng" },
    { src: "/assets/images/Screenshot_20240918-135605_QTimeTable.png", alt: "Màn hình xem thời khóa biểu", description: "Màn hình xem thời khóa biểu" },
    { src: "/assets/images/Screenshot_20240918-135618_QTimeTable.png", alt: "Màn hình xem lịch thi", description: "Màn hình xem lịch thi" },
    { src: "/assets/images/Screenshot_20240918-135623_QTimeTable.png", alt: "Màn hình xem chi tiết lịch", description: "Màn hình xem lịch hiển thị chi tiết các ngày có lịch học, lịch thi" },
    { src: "/assets/images/Screenshot_20240918-135629_QTimeTable.png", alt: "Màn hình xem điểm theo học kỳ", description: "Màn hình xem điểm theo học kỳ" },
    { src: "/assets/images/Screenshot_20240918-135636_QTimeTable.png", alt: "Màn hình xem điểm theo môn học", description: "Màn hình xem điểm theo môn học" },
    { src: "/assets/images/Screenshot_20240918-135644_QTimeTable.png", alt: "Màn hình hiển thị Tab Menu", description: "Màn hình hiển thị Tab Menu" },
  ];
  const [currentDescription, setCurrentDescription] = useState(screenshots[0].description);
return (
    <div className={`min-h-screen ${darkMode ? 'dark' : ''}`}>
      <div className="min-h-screen bg-gray-50 dark:bg-gray-900 transition-colors duration-500">
        {/* Enhanced Header */}
        <motion.header 
          className="bg-gradient-to-r from-blue-600 to-purple-600 dark:from-blue-800 dark:to-purple-800 text-white p-4 fixed w-full z-10"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <div className="container mx-auto flex justify-between items-center">
            <motion.h1 
              className="text-3xl font-bold"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <img src="/assets/images/icon.png" alt="QTimeTable" className="w-14 h-14 inline-block mr-2" />
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-400 dark:from-blue-500 dark:to-purple-500">QTimeTable</span>
            </motion.h1>
            <div className="flex items-center">
              <motion.button
                onClick={toggleDarkMode}
                className="mr-4 p-2 rounded-full hover:bg-white/20 transition-colors duration-300"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              >
                {darkMode ? <Sun className="w-6 h-6" /> : <Moon className="w-6 h-6" />}
              </motion.button>
              <nav className="hidden md:block">
                <ul className="flex space-x-6">
                  {['Tính năng', 'Ảnh demo', 'Giới thiệu', 'Tải xuống'].map((item, index) => (
                    <motion.li key={index} whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
                      <a href={`#${item.toLowerCase().replace(' ', '-')}`} className="hover:text-blue-200 transition-colors duration-300">
                        {item}
                      </a>
                    </motion.li>
                  ))}
                </ul>
              </nav>
              <motion.button
                onClick={toggleMenu}
                className="md:hidden p-2 rounded-full hover:bg-white/20 transition-colors duration-300"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              >
                {menuOpen ? <X className="w-6 h-6" /> : <Menu className="w-6 h-6" />}
              </motion.button>
            </div>
          </div>
          {menuOpen && (
            <motion.nav 
              className="md:hidden mt-4"
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
            >
              <ul className="flex flex-col space-y-2">
                {['Tính năng', 'Ảnh demo', 'Giới thiệu', 'Tải xuống'].map((item, index) => (
                  <motion.li 
                    key={index}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                  >
                    <a 
                      href={`#${item.toLowerCase().replace(' ', '-')}`} 
                      className="block py-2 hover:bg-white/20 transition-colors duration-300" 
                      onClick={toggleMenu}
                    >
                      {item}
                    </a>
                  </motion.li>
                ))}
              </ul>
            </motion.nav>
          )}
        </motion.header>

        {/* Enhanced Hero Section */}
        <motion.section 
          className="bg-gradient-to-r from-blue-500 to-purple-500 dark:from-blue-700 dark:to-purple-700 text-white pt-32 pb-20"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
        >
          <div className="container mx-auto text-center px-4">
            <motion.h2 
              className="text-5xl md:text-6xl font-bold mb-6"
              variants={fadeInUp}
              initial="hidden"
              animate="visible"
              transition={{ duration: 0.6, delay: 0.2 }}
            >
              Quản lý thời gian học tập dễ dàng
            </motion.h2>
            <motion.p 
              className="text-xl md:text-2xl mb-10"
              variants={fadeInUp}
              initial="hidden"
              animate="visible"
              transition={{ duration: 0.6, delay: 0.4 }}
            >
              Ứng dụng xem thời khóa biểu, lịch thi và điểm số dành cho sinh viên <i className='font-semibold'>Trường Đại học Công nghệ Thông tin & Truyền thông Thái Nguyên</i>
            </motion.p>
            <motion.a 
              href="#tải-xuống" 
              className="bg-white text-blue-500 px-8 py-4 rounded-full font-bold text-lg hover:bg-blue-100 transition duration-300 shadow-lg inline-block"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              variants={fadeInUp}
              initial="hidden"
              animate="visible"
              transition={{ duration: 0.6, delay: 0.6 }}
            >
              Tải ứng dụng ngay
            </motion.a>
          </div>
        </motion.section>

        {/* Enhanced Features Section */}
        <section id="tính-năng" className="py-20 bg-white dark:bg-gray-800">
          <div className="container mx-auto px-4">
            <motion.h2 
              className="text-4xl font-bold text-center mb-16 dark:text-white"
              variants={fadeInUp}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.6 }}
            >
              Tính năng chính
            </motion.h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
              {[
                { icon: Calendar, title: "Xem thời khóa biểu", description: "Dễ dàng theo dõi lịch học, lịch thi của bạn với giao diện trực quan và thông minh" },
                { icon: GraduationCap, title: "Xem điểm số", description: "Cập nhật kết quả học tập nhanh chóng và theo dõi tiến độ của bạn" },
                { icon: Clock, title: "Nhắc nhở thông minh", description: "Không bao giờ bỏ lỡ một buổi học quan trọng với hệ thống thông báo tùy chỉnh" }
              ].map((feature, index) => (
                <motion.div 
                  key={index}
                  className="text-center bg-gray-100 dark:bg-gray-700 p-8 rounded-xl shadow-lg"
                  variants={fadeInUp}
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                  transition={{ duration: 0.6, delay: index * 0.2 }}
                  whileHover={{ scale: 1.05 }}
                >
                  <feature.icon className="w-20 h-20 mx-auto text-blue-500 dark:text-blue-400 mb-6" />
                  <h3 className="text-2xl font-semibold mb-4 dark:text-white">{feature.title}</h3>
                  <p className="dark:text-gray-300">{feature.description}</p>
                </motion.div>
              ))}
            </div>
          </div>
        </section>
      {/* Enhanced Screenshots Section */}
      <section id="ảnh-demo" className="py-20 bg-gradient-to-b from-gray-100 to-white dark:from-gray-900 dark:to-gray-800">
      <div className="container mx-auto px-4">
        <motion.h2 
          className="text-3xl md:text-4xl font-bold text-center mb-16 dark:text-white"
          variants={fadeInUp}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
        >
          Ảnh demo ứng dụng
        </motion.h2>
        <div className="flex flex-col md:flex-row items-center justify-center gap-8">
          <motion.div 
            className="w-full max-w-sm"
            variants={fadeInUp}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.6, delay: 0.2 }}
          >
            <div className="relative phone-mockup">
              <div className="absolute inset-0 bg-black rounded-[2.5rem] shadow-lg"></div>
              <div className="relative border-[14px] border-black rounded-[2.5rem] overflow-hidden">
                <Carousel 
                  showArrows={true}
                  showStatus={false}
                  showThumbs={false}
                  infiniteLoop={true}
                  autoPlay={true}
                  interval={5000}
                  onChange={(index) => setCurrentDescription(screenshots[index].description)}
                  renderArrowPrev={(onClickHandler, hasPrev) =>
                    hasPrev && (
                      <button onClick={onClickHandler} className="absolute left-4 top-1/2 z-10 -translate-y-1/2 bg-white/80 rounded-full p-2 hover:bg-white transition-colors">
                        <ChevronLeft className="w-6 h-6" />
                      </button>
                    )
                  }
                  renderArrowNext={(onClickHandler, hasNext) =>
                    hasNext && (
                      <button onClick={onClickHandler} className="absolute right-4 top-1/2 z-10 -translate-y-1/2 bg-white/80 rounded-full p-2 hover:bg-white transition-colors">
                        <ChevronRight className="w-6 h-6" />
                      </button>
                    )
                  }
                >
                  {screenshots.map((screenshot, index) => (
                    <div key={index} className="aspect-[9/16]">
                      <img
                        src={screenshot.src}
                        alt={screenshot.alt}
                        className="w-full h-full object-cover"
                      />
                    </div>
                  ))}
                </Carousel>
              </div>
            </div>
          </motion.div>
          <motion.div 
            className="w-full max-w-md"
            variants={fadeInUp}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.6, delay: 0.4 }}
          >
            <div className="bg-white dark:bg-gray-800 shadow-lg rounded-lg p-6">
              <h3 className="text-xl font-semibold mb-4 dark:text-white">Mô tả</h3>
              <p className="text-gray-600 dark:text-gray-300">{currentDescription}</p>
            </div>
          </motion.div>
        </div>
      </div>
    </section>

        {/* Enhanced About Section */}
        <section id="giới-thiệu" className="py-20 bg-gradient-to-r from-blue-500 to-purple-500 dark:from-blue-700 dark:to-purple-700 text-white">
          <div className="container mx-auto px-4">
            <motion.h2 
              className="text-4xl font-bold text-center mb-8"
              variants={fadeInUp}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.6 }}
            >
              Về QTimeTable
            </motion.h2>
            <motion.p 
              className="text-center max-w-2xl mx-auto text-lg"
              variants={fadeInUp}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.6, delay: 0.2 }}
            >
              QTimeTable là một ứng dụng mã nguồn mở được phát triển bởi và dành cho sinh viên ICTU. 
              Giúp bạn có thể theo dõi thời khóa biểu, lịch thi và điểm số một cách dễ dàng và tiện lợi.
              Với giao diện thân thiện và tính năng đa dạng, QTimeTable sẽ là người bạn đồng hành đáng tin cậy trong suốt quá trình học tập của bạn.
              <br/>
              <br/>
              <span className="font-semibold mt-4 text-xl">Ứng dụng được phát triển bởi <a href="https://quanhd.net" className="hover:underline text-red-200 transition duration-300">QuanHD</a></span>
            </motion.p>
          </div>
        </section>

        {/* Enhanced Download Section */}
        <section id="tải-xuống" className="py-20 bg-white dark:bg-gray-800">
          <div className="container mx-auto text-center px-4">
            <motion.h2 
              className="text-4xl font-bold mb-8 dark:text-white"
              variants={fadeInUp}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.6 }}
            >
              Tải xuống QTimeTable
            </motion.h2>
            <motion.p 
              className="mb-6 text-gray-700 dark:text-gray-300 text-lg"
              variants={fadeInUp}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.6, delay: 0.2 }}
            >
              QTimeTable hiện có sẵn cho Android thông qua file APK. Hãy tải xuống ngay để trải nghiệm!
            </motion.p>
            {loading ? (
              <div className="flex justify-center items-center">
                <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
              </div>
            ) : error ? (
              <motion.p 
                className="text-red-500"
                variants={fadeInUp}
                initial="hidden"
                animate="visible"
                transition={{ duration: 0.6 }}
              >
                {error}
              </motion.p>
            ) : appInfo ? (
              <motion.div
                variants={fadeInUp}
                initial="hidden"
                animate="visible"
                transition={{ duration: 0.6, delay: 0.4 }}
              >
                <p className="mb-6 text-gray-600 dark:text-gray-400">
                  Phiên bản hiện tại: <span className="font-semibold">{appInfo.app_version}</span>
                </p>
                <motion.a 
                  href={appInfo.download_url}
                  className="bg-green-500 text-white px-8 py-4 rounded-lg inline-flex items-center text-lg font-bold hover:bg-green-600 transition duration-300 shadow-lg"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  <svg className="w-6 h-6 mr-2" viewBox="0 0 24 24" fill="currentColor">
                    <path d="M17.523 15.34l.003-.006a1.113 1.113 0 01-1.106 1.334h-.72c-.232 0-.42.19-.42.422v3.185c0 .62-.504 1.125-1.125 1.125h-1.406c-.31 0-.562-.252-.562-.563v-3.746c0-.233-.189-.422-.422-.422H9.562c-.232 0-.42.189-.42.422v3.746c0 .31-.253.563-.563.563H7.125A1.125 1.125 0 016 20.275v-3.185c0-.232-.189-.422-.422-.422h-.72a1.114 1.114 0 01-1.105-1.334l.002.006 1.72-9.664C5.607 4.825 6.392 4 7.29 4h9.42c.898 0 1.683.825 1.815 1.677zm-8.86-8.778a.75.75 0 10-1.5 0v2.625a.75.75 0 001.5 0zm6.674 0a.75.75 0 10-1.5 0v2.625a.75.75 0 001.5 0z"/>
                  </svg>
                  Tải APK cho Android
                </motion.a>
              </motion.div>
            ) : (
              <motion.p 
                className="text-gray-600 dark:text-gray-400"
                variants={fadeInUp}
                initial="hidden"
                animate="visible"
                transition={{ duration: 0.6 }}
              >
                Không có thông tin tải xuống.
              </motion.p>
            )}
          </div>
        </section>

        {/* Enhanced Footer */}
        <footer className="bg-gray-800 text-white py-8">
          <div className="container mx-auto text-center px-4">
            <motion.p 
              className="mb-4"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.6 }}
            >
              &copy; {new Date().getFullYear()} QTimeTable. All rights reserved.<br/>Made with ❤️ by <a href="https://quanhd.net" className="hover:text-gray-300 transition duration-300">QuanHD</a>
            </motion.p>
            <motion.a 
              href="https://github.com/HDQuanDev/qtimetable-ictu"
              target='_blank'
              className="inline-flex items-center hover:text-gray-300 transition duration-300"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.6, delay: 0.2 }}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <Github className="w-6 h-6 mr-2" />
              Xem mã nguồn trên GitHub
            </motion.a>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default App;